import axios from 'axios'

const API_BASE_URL = process.env.VUE_APP_API_BASE_URL
const SIGNUP_BASE_URL = process.env.VUE_APP_SIGNUP_BASE_URL
const GET_TOKEN_URL = process.env.VUE_APP_GET_TOKEN_URL
// axios.defaults.withCredentials=true

export const request = (options) => {
    let headers = {
        'Content-Type': 'application/json'
    }

    if (options.headers) {
        headers = {...headers, ...options.headers}
        delete options["headers"]
    }

    const defaults = {headers: headers};
    options = Object.assign({}, defaults, options, {withCredentials: true})

    return axios(options)

}

export const getToken = (options) => {

    let data = new FormData()
    data.append('username', options.username)
    data.append('password', options.password)
    let populated_option = {
        url: GET_TOKEN_URL,
        headers : {
            'accountType': options.accountType,
            'remember':options.remember,
        },
        config: {
            headers: {'Content-Type': 'mutipart/form-data'}
        },
        method: 'post',
        data: data,
        withCredentials: true

    }

    return axios(populated_option)
}
export const signup = signupRequest => request({
    url: SIGNUP_BASE_URL + 'auth/signup/organizer',
    data: JSON.stringify(signupRequest),
    method: 'post'
})

export const signout= options => {
    return request({
        url: API_BASE_URL + 'logout',
        method: 'get',
        ...options
    })
}

export const getOrganizer= options => {
    return request({
        url: API_BASE_URL + 'organizer',
        method: 'get',
        ...options
    })
}

export const getAdmin = options => {
    return request({
        url: API_BASE_URL + 'admin',
        method: 'get',
        ...options,
    })
}

export const getMe = options => {
    return request({
        url: API_BASE_URL + `${options.accountType}/me`,
        method: 'get',
        ...options
    })
}

export const getClientsEvents = options => {
  return request({
    url: API_BASE_URL + 'event/clients/events',
    method: 'get',
    ...options,
  })
}

export const readyEditEvent = options => {
  return request({
    url: API_BASE_URL + 'event/ready_edit/' + options.eventId,
    method: 'get',
    ...options,
  })
}

export const getOnlineEventPlatformsList = options => {
  return request({
    url: API_BASE_URL + 'event/' + options.eventId + '/onlineEventPlatforms',
    method: 'get',
    ...options,
  })
}

export const saveEvent = options => {
    return request({
        url: API_BASE_URL + 'event',
        method: 'post',
        ...options
    })
}

export const updateEvent = options => {
    return request({
        url: API_BASE_URL + 'event/' + `${options.data.id}`,
        method: 'put',
        ...options
    })
}

export const deleteEvent = options => {
    return request({
        url: API_BASE_URL + 'event/' + `${options.eventId}`,
        method: 'delete',
        ...options
    })
}

export const readyEditMyPage = options => {
  return request({
    url: API_BASE_URL + 'mypage/ready_edit/' + options.eventId,
    method: 'get',
    ...options,
  })
}

export const updateMyPage = options => {
  return request({
    url: API_BASE_URL + 'mypage/' + `${options.eventId}`,
    method: 'put',
    ...options
  })
}

export const updateMyPageExpiration = options => {
  return request({
    url: API_BASE_URL + 'mypage/expiration',
    method: 'post',
    data: options
  })
}

export const getMyPage = options => {
  return request({
    url: API_BASE_URL + 'mypage/' + options.eventId + '/edit',
    method: 'get',
    ...options
  })
}

export const downloadSampleTicket = options => {
  return request({
      url: API_BASE_URL + 'event/downloadSampleTicket',
      method: 'post',
      responseType: 'arraybuffer',
      data: options
  })
}

export const getTicketRemainCounts = options => {
  return request({
    url: API_BASE_URL + 'event/' + options.eventId + '/ticket_remain_counts',
    method: 'get',
    ...options
  })
}

export const getFacilities = options => {
    return request({
        url: API_BASE_URL + 'facility/all/active',
        method: 'get',
        ...options
    })
}

export const getAllVenues = options => {
    return request({
        url: API_BASE_URL + 'facility/all',
        method: 'get',
        ...options
    })
}

export const getEvent = options => {
    return request({
        url: API_BASE_URL + 'event/' + options.eventId,
        method: 'get',
        ...options
    })
}

export const getEventHeader = options => {
  return request({
    url: API_BASE_URL + 'event/header/' + options.eventId,
    method: 'get',
    ...options
  })
}

export const getEventVisitor = options => {
    return request({
        url: API_BASE_URL + 'event/' + options.eventId + '/visitor',
        method: 'get',
        ...options
    })
}

export const applyEvent = options => {
    return request({
        url: API_BASE_URL + 'visitor/',
        method: 'post',
        ...options
    })
}

export const getReceiptUrlForOrganizer = options => {
  return API_BASE_URL + 'visitor/' + options.visitorId + '/receiptForOrganizer'
}

export const getReceiptsZip = options => {
  return API_BASE_URL + 'visitor/' + options.eventId + '/receiptsZip'
}

export const getVisitorLogs = options => {
  return request({
    url: API_BASE_URL + 'visitor/' + options.eventId + '/visitorLogs',
    method: 'get',
    ...options
  })
}

export const exportVisitorLogsCsvFile = options => {
  return API_BASE_URL + "visitor/" + options.eventId + "/visitorLogs/export/visitorLogs.csv"
}

export const uploadPhoto = (file) => {
    let reader = new FileReader()
    let formData = new FormData()

    formData.append("photo",file);

    return axios.post(API_BASE_URL+"photo",
        formData,
        {
            withCredentials: true,
            headers:{
                'Content-Type':'multipart/form-data',
            }
        }
    )
}

export const getFileList = options => {
    return request({
        url: API_BASE_URL + 'file/' + `${options.eventId}` + '/all',
        method: 'get',
        ...options
    })
}

export const uploadFile = options => {
    let reader = new FileReader()
    let formData = new FormData()
    let eventId = options.eventId
    formData.append("file",options.file)

    return axios.post(API_BASE_URL+`file/${eventId}`,
        formData,
        {
            withCredentials: true,
            headers:{
                'Content-Type':'multipart/form-data'
            }
        }
    )
}

export const deleteFile = options => {
    return request({
        url: API_BASE_URL + 'file/' + `${options.eventId}` + '/' + `${options.fileName}`,
        method: 'delete',
        ...options
    })
}

export const setShare = options => {
    return request({
        url: API_BASE_URL + 'file/' + `${options.eventId}` + '/setshare',
        method: 'post',
        ...options
    })
}

export const getOperationLogs = options => {
    return request({
        url: API_BASE_URL + 'auditLog',
        method: 'get',
        ...options
    })
}

export const getOrganizers = options => {
    return request({
        url: API_BASE_URL + 'organizer/all',
        method: 'get',
        ...options
    })
}

export const createOrganizer = options => {
    return request({
        url: API_BASE_URL + 'organizer/',
        method: 'post',
        ...options
    })
}

export const updateOrganizer = options => {
    return request({
        url: API_BASE_URL + 'organizer/' + options.data.id,
        method: 'put',
        ...options
    })
}

export const deleteOrganizer = options => {
    return request({
        url: API_BASE_URL + 'organizer/' + options.id,
        method: 'delete',
        ...options
    })
}

export const getOrganizerOptions = options => {
  return request({
    url: API_BASE_URL + 'organizer/getOptions',
    method: 'get',
    ...options
  })
}

export const isEnableZoomIsv = options => {
  return request({
    url: API_BASE_URL + 'organizer/isEnableZoomIsv',
    method: 'get',
    ...options
  })
}

export const getDefaultEmailType = options => {
  return request({
    url: API_BASE_URL + 'organizer/defaultEmailType',
    method: 'get',
    ...options
  })
}

export const getDefaultUrls = options => {
  return request({
    url: API_BASE_URL + 'organizer/defaultUrls',
    method: 'get',
    ...options
  })
}

export const sendPasswordResetToken = options => {
    return request({
        url: API_BASE_URL + 'forget',
        method: 'post',
        ...options
    })
}

export const isValidToken = options => {
    return request({
        url: API_BASE_URL + 'checkpwtoken',
        method: 'post',
        ...options
    })
}

export const resetPassword = options => {
    return request({
        url: API_BASE_URL + 'resetpw',
        method: 'post',
        ...options
    })
}

export const getAllCustomMailTemplatesList = options => {
  return request({
    url: API_BASE_URL + 'custommailtemplate/all/list',
    method: 'get',
    ...options
  })
}
export const getAllCustomMailTemplates = options => {
  return request({
    url: API_BASE_URL + 'custommailtemplate/all',
    method: 'get',
    ...options
  })
}
export const createCustomMailTemplate = options => {
  return request({
    url: API_BASE_URL + 'custommailtemplate',
    method: 'post',
    ...options
  })
}
export const updateCustomMailTemplate = options => {
  return request({
    url: API_BASE_URL + 'custommailtemplate',
    method: 'put',
    ...options
  })
}
export const deleteCustomMailTemplates = options => {
  return request({
    url: API_BASE_URL + 'custommailtemplate/' + options.mailTemplateId,
    method: 'delete',
    ...options
  })
}


export const getDefaultEmailFrom = options => {
    return request({
        url: API_BASE_URL + 'email/default_email_from',
        method: 'get',
        ...options
    })
}


export const sendEmail = options => {
    return request({
        url: API_BASE_URL + 'email',
        method: 'post',
        ...options
    })
}

export const getEmailList = options => {
  return request({
    url: API_BASE_URL + 'email/email_list/' + options.eventId,
    method: 'get',
    ...options
  })
}

export const getEmail = options => {
  return request({
    url: API_BASE_URL + 'email/' + options.eventId+ '/get_email/' + options.mailId,
    method: 'get',
    ...options
  })
}

export const getEmailReports = options => {
  return request({
    url: API_BASE_URL + 'email/' + options.eventId+ '/get_email_reports/' + options.mailId,
    method: 'get',
    ...options
  })
}

export const exportEmailReportsCsvFile = options => {
  return API_BASE_URL + 'email/' + options.eventId + '/' + options.mailId + "/email_reports/email_reports.csv"
}

export const updateEmail = options => {
  return request({
    url: API_BASE_URL + 'email/update',
    method: 'post',
    ...options
  })
}

export const deleteEmail = options => {
  return request({
    url: API_BASE_URL + "email/" + options.eventId + '/delete/' + options.mailId,
    method: 'post',
    ...options
  })
}

export const exportCsvFile = options => {
    return API_BASE_URL + "visitor/" + options.eventId + "/visitor.csv"
}

export const exportNotificationsCsvFile = options => {
  return API_BASE_URL + "visitor/" + options.eventId + "/notifications/visitor.csv"
}

export const exportReceptionsCsvFile = options => {
  return API_BASE_URL + "visitor/" + options.eventId + "/receptions/visitor.csv"
}

export const downloadCsvTemplate = options => {
  return request({
    url: API_BASE_URL + 'visitor/template/template.csv',
    method: 'post',
    responseType: 'arraybuffer',
    data: options
  })
}

export const createFacility = options => {
    return request({
        url: API_BASE_URL + 'facility',
        method: 'post',
        ...options
    })
}

export const updateFacility = options => {
    return request({
        url: API_BASE_URL + 'facility/' + options.id,
        method: 'put',
        ...options
    })
}

export const deleteFacility = options => {
    return request({
        url: API_BASE_URL + 'facility/' + options.id,
        method: 'delete',
        ...options
    })
}

export const getLatLng = options => {
  return request({
    url: API_BASE_URL + 'facility/get_lat_lng',
    method: 'post',
    ...options
  })
}

export const getGoogleStaticMapUrl = options => {
  return request({
    url: API_BASE_URL + 'facility/get_google_static_map_url',
    method: 'post',
    ...options
  })
}

export const importCsvFile = options => {
    let formData = new FormData()

    formData.append("file", options.file)

    let config = {
        withCredentials: true,
        headers: {
            'Content-Type':'multipart/form-data'
        }
    }

    return axios.post(API_BASE_URL + 'visitor/' + options.eventId + '/csv/import',
        formData,
        config
    )
}

export const importCsvFileIncludeTicket = options => {
  let formData = new FormData()

  formData.append("file", options.file)
  formData.append("ignoreTicketStock", options.ignoreTicketStock)

  let config = {
    withCredentials: true,
    headers: {
      'Content-Type':'multipart/form-data'
    }
  }

  return axios.post(API_BASE_URL + 'visitor/' + options.eventId + '/csv/import/' + options.ticketId,
    formData,
    config
  )
}

export const importNotificationsCsvFile = options => {
  let formData = new FormData()

  formData.append("file", options.file)

  let config = {
    withCredentials: true,
    headers: {
      'Content-Type':'multipart/form-data'
    }
  }

  return axios.post(API_BASE_URL + 'visitor/' + options.eventId + '/notifications/csv/import',
    formData,
    config
  )
}

export const attendVisitor = options => {
    return request({
        url: API_BASE_URL + 'visitor/attend/' + options.eventId,
        method: 'post',
        ...options
    })
}

export const deleteVisitor = options => {
    return request({
        url: API_BASE_URL + 'visitor/delete/' + options.eventId,
        method: 'post',
        ...options
    })
}

export const cancelVisitor = options => {
    return request({
        url: API_BASE_URL + 'visitor/cancel/' + options.eventId,
        method: 'post',
        ...options
    })
}

export const updateVisitor = options => {
    return request({
        url: API_BASE_URL + 'visitor/update',
        method: 'post',
        ...options
    })
}

export const bulkSetVisitorPassword = options => {
  return request({
    url: API_BASE_URL + 'visitor/bulkSetPassword',
    method: 'post',
    data: options,
    ...options
  })
}

export const doRecoveryVisitor = options => {
  return request({
    url: API_BASE_URL + 'visitor/doRecovery',
    method: 'post',
    data: options,
    ...options
  })
}

export const createVisitor = options => {
    return request({
        url: API_BASE_URL + 'visitor/',
        method: 'post',
        ...options
    })
}

export const createAdmin = options => {
    return request({
        url: API_BASE_URL + 'admin/',
        method: 'post',
        ...options
    })
}

export const createClient = options => {
    return request({
        url: API_BASE_URL + 'client/',
        method: 'post',
        ...options
    })
}
export const updateClient = options => {
    return request({
        url: API_BASE_URL + 'client/update/' + options.data.id,
        method: 'put',
        ...options
    })
}
export const deleteClient = options => {
    return request({
        url: API_BASE_URL + 'client/' + options.id,
        method: 'delete',
        ...options
    })
}
export const getClient = options => {
  return request({
    url: API_BASE_URL + 'client/' + options.id,
    method: 'get',
    ...options
  })
}
export const getClients = options => {
    return request({
        url: API_BASE_URL + 'client/all',
        method: 'get',
        ...options
    })
}
export const recoveryClient = options => {
  return request({
    url: API_BASE_URL + 'client/recovery/' + options.id,
    method: 'get',
    ...options
  })
}
export const sendClientInvitation = options => {
    return request( {
        url: API_BASE_URL + 'client/invitation/' + options.id,
        method: 'put',
        ...options
    })
}
export const sendClientPasswordReset = options => {
  return request( {
    url: API_BASE_URL + 'client/passwordreset/' + options.id,
    method: 'put',
    ...options
  })
}
export const getClientSharedVideoStatus = options => {
  return request({
    url: API_BASE_URL + 'client/sharedVideoStatus/' + options.id,
    method: 'get',
    ...options
  })
}

export const createGroup = options => {
    return request({
        url: API_BASE_URL + 'group/create',
        method: 'post',
        ...options
    })
}

export const getGroupsAndEvents = options => {
  return request({
    url: API_BASE_URL + 'group/events',
    method: 'get',
    ...options
  })
}

export const getBelongingGroup = options => {
    return request({
        url: API_BASE_URL + 'group',
        method: 'get',
        ...options
    })
}

export const getGroup = options => {
    return request({
        url: API_BASE_URL + 'group/' + options.id,
        method: 'get',
        ...options
    })
}
export const updateGroup = options => {
    return request({
        url: API_BASE_URL + 'group/update',
        method: 'put',
        ...options
    })
}
export const inviteGroupMember = options => {
    return request({
        url: API_BASE_URL + 'group/member/invite',
        method: 'post',
        ...options
    })
}
export const addGroupMember = options => {
    return request({
        url: API_BASE_URL + 'group/member/add',
        method: 'post',
        ...options
    })
}
export const recoveryGroupMember = options => {
  return request({
    url: API_BASE_URL + 'group/member/recovery',
    method: 'post',
    data: options,
    ...options
  })
}
export const isValidInvitationToken = options => {
    return request({
        url: API_BASE_URL + 'group/member/token/validate',
        method: 'post',
        ...options
    })
}
export const deleteGroupMember = options => {
    return request({
        url: API_BASE_URL + 'group/member/expel',
        method: 'delete',
        ...options
    })
}
export const deleteGroup = options => {
    console.log("options", options)
    return request({
        url: API_BASE_URL + 'group/' + options.groupId,
        method: 'delete',
        ...options
    })
}
export const getAllPlans = options => {
    return request({
        url: API_BASE_URL + 'plan/all',
        method: 'get',
        ...options
    })
}
export const createPlan = options => {
    return request({
        url: API_BASE_URL + 'plan',
        method: 'post',
        ...options
    })
}
export const updatePlan = options => {
    return request({
        url: API_BASE_URL + 'plan',
        method: 'put',
        ...options
    })
}
export const deletePlan = options => {
    return request({
        url: API_BASE_URL + 'plan/' + options.planId,
        method: 'delete',
        ...options
    })
}
export const getAllAnnouncements = options => {
  return request({
    url: API_BASE_URL + 'announcement/all',
    method: 'get',
    ...options
  })
}
export const createAnnouncement = options => {
  return request({
    url: API_BASE_URL + 'announcement',
    method: 'post',
    ...options
  })
}
export const updateAnnouncement = options => {
  return request({
    url: API_BASE_URL + 'announcement',
    method: 'put',
    ...options
  })
}
export const deleteAnnouncement = options => {
  return request({
    url: API_BASE_URL + 'announcement/' + options.announcementId,
    method: 'delete',
    ...options
  })
}
export const getLastOpenAnnouncement = options => {
  return request({
    url: API_BASE_URL + 'announcement/lastOpen',
    method: 'get',
    ...options
  })
}
export const getAllOpenAnnouncements = options => {
  return request({
    url: API_BASE_URL + 'announcement/allOpens',
    method: 'get',
    ...options
  })
}

export const isAcceptTermOfService = options => {
    return request({
        url: API_BASE_URL + 'organizer/term_of_service',
        method: 'get',
        ...options
    })
}

export const acceptTermOfService = options => {
    return request({
        url: API_BASE_URL + 'organizer/term_of_service',
        method: 'put',
        ...options
    })
}
export const getMyRoleAtGroup = options => {
  return request({
    url: API_BASE_URL + 'organizer/group/' + options.groupId + '/role',
    method: 'get',
    ...options
  })
}
export const getAffiliationRoles = options => {
  return request({
    url: API_BASE_URL + 'group/member/affiliation_roles',
    method: 'get',
    ...options
  })
}


export const getCurrentConsumptionTaxRatio = options => {
  return request({
    url: API_BASE_URL + 'payment/currentConsumptionTaxRatio',
    method: 'get',
    ...options
  })
}


export const getPaymentServiceStatusThisOwner = options => {
  return request({
    url: API_BASE_URL + 'payment/owner/paymentServiceStatus',
    method: 'get',
    ...options
  })
}

export const isThisEventPaymentEnable = options => {
  return request({
    url: API_BASE_URL + 'payment/event/' + options.eventId + '/is_enable',
    method: 'get',
    ...options
  })
}

export const getListOfSqLocationsBySquareAccount = options => {
  return request({
    url: API_BASE_URL + 'squareAccount/list_locations'
      + '/' + `${options.squareAccountId}`,
    method: 'get',
    ...options
  })
}
export const getSquareAccountSettingNames = options => {
  return request({
    url: API_BASE_URL + 'squareAccount/setting_names'
      + '/' + `${options.squareAccountId}`
      + '/' + `${options.squareLocationId}`
    ,
    method: 'get',
    ...options
  })
}
export const getSquareAccountType = options => {
  return request({
    url: API_BASE_URL + 'square/account_type',
    method: 'get',
    ...options
  })
}
export const getSquareEventSettings = options => {
  return request({
    url: API_BASE_URL + 'square/event/settings'
      + '/' + `${options.eventId}`,
    method: 'get',
    ...options
  })
}

export const getSquareAccounts = options => {
  return request({
    url: API_BASE_URL + 'squareAccount/all',
    method: 'get',
    ...options
  })
}
export const getSquareAccount = options => {
  return request({
    url: API_BASE_URL + 'squareAccount/' + `${options.squareAccountId}`,
    method: 'get',
    ...options
  })
}
export const getAllowAssignSquareAccounts = options => {
  return request({
    url: API_BASE_URL + 'squareAccount/allow_assign',
    method: 'get',
    ...options
  })
}
export const checkExistsSquareAccount = options => {
  return request({
    url: API_BASE_URL + 'squareAccount/checkExists',
    method: 'post',
    data: options,
    ...options
  })
}
export const addSquareAccount = options => {
  return request({
    url: API_BASE_URL + 'squareAccount',
    method: 'post',
    data: options,
    ...options
  })
}
export const updateSquareAccount = options => {
  return request({
    url: API_BASE_URL + 'squareAccount',
    method: 'put',
    data: options,
    ...options
  })
}
export const deleteSquareAccounts = options => {
  return request({
    url: API_BASE_URL + 'squareAccount/delete',
    method: 'post',
    ...options
  })
}
export const getSquareAccountOAuthUrl = options => {
  return request({
    url: API_BASE_URL + 'squareAccount/oauth/url',
    method: 'get',
    ...options
  })
}
export const saveSquareAccountAccessToken = options => {
  return request({
    url: API_BASE_URL + 'squareAccount/oauth/save/token'
      + '/' + `${options.squareAccountId}`
      + '/' + `${options.code}`,
    method: 'get',
    ...options
  })
}
export const getSqOauthReturnDomain = options => {
  return request({
    url: API_BASE_URL + 'square/oauth/callback/return_domain',
    method: 'get',
    ...options
  })
}
export const getSqOauthSessionId = options => {
  return request({
    url: API_BASE_URL + 'square/oauth/callback/session'
      + '/' + options.squareAccountId,
    method: 'get',
    ...options
  })
}
export const getSqOauthAfterCallbackSquareAccount = options => {
  return request({
    url: API_BASE_URL + 'square/oauth/after_callback/square_account'
      + '/' + options.sessionId
      + '/' + options.code,
    method: 'get',
    ...options
  })
}

export const checkPaymentInfoCanBeDeleted = options => {
  return request({
    url: API_BASE_URL + 'event/' + options.eventId + '/checkPaymentInfoCanBeDeleted',
    method: 'get',
    ...options
  })
}

export const getAllReturnPoliciesList = options => {
  return request({
    url: API_BASE_URL + 'returnpolicies/all/list',
    method: 'get',
    ...options
  })
}
export const getAllReturnPolicies = options => {
  return request({
    url: API_BASE_URL + 'returnpolicies/all',
    method: 'get',
    ...options
  })
}
export const getReturnPolicy = options => {
  return request({
    url: API_BASE_URL + 'returnpolicies/' + options.policyId,
    method: 'get',
    ...options
  })
}
export const createReturnPolicy = options => {
  return request({
    url: API_BASE_URL + 'returnpolicies',
    method: 'post',
    ...options
  })
}
export const updateReturnPolicy = options => {
  return request({
    url: API_BASE_URL + 'returnpolicies',
    method: 'put',
    ...options
  })
}
export const deleteReturnPolicies = options => {
  return request({
    url: API_BASE_URL + 'returnpolicies/' + options.returnPolicyId,
    method: 'delete',
    ...options
  })
}

export const setDefaultReturnPolicy = options => {
  return request({
    url: API_BASE_URL + 'returnpolicies/default/' + options.returnPolicyId,
    method: 'put',
    ...options
  })
}

export const clearDefaultReturnPolicy = options => {
  return request({
    url: API_BASE_URL + 'returnpolicies/default/',
    method: 'delete',
    ...options
  })
}

export const getDefaultReturnPolicy = options => {
  return request({
    url: API_BASE_URL + 'returnpolicies/default/',
    method: 'get',
    ...options
  })
}

export const getAllowAssignReturnPolicies = options => {
  return request({
    url: API_BASE_URL + 'returnpolicies/event/allowAssignReturnPolicies'
      + '/' + `${options.eventGroupId}`,
    method: 'get',
    ...options
  })
}

export const getSalesStatus = options => {
  return request({
    url: API_BASE_URL + 'event/' + options.eventId + '/ticket_sales',
    method: 'get',
    data: options,
    ...options
  })
}

export const checkCanBeDeleted = options => {
  return request({
    url: API_BASE_URL + 'event/' + options.ticketId + '/enable_delete',
    method: 'get',
    ...options
  })
}

export const getCancelledVisitor = options => {
  return request({
    url: API_BASE_URL + 'visitor/' + options.eventId + '/cancelled_visitor',
    method: 'get',
    ...options
  })
}

export const postMailActivities = options => {
    return request({
      url: API_BASE_URL + 'activity',
      method: 'post',
      ...options
    })
}

export const getSharedVideoPlatformsList = options => {
  return request({
    url: API_BASE_URL + 'sharedvideo/' + options.eventId + '/videoPlatforms',
    method: 'get',
    ...options,
  })}

export const getSharedVideosList = options => {
  return request({
    url: API_BASE_URL + 'sharedvideo/' + options.eventId + '/all',
    method: 'get',
    ...options,
  })
}

export const addSharedVideo = options => {
  console.log('options:', JSON.stringify(options))
  return request({
    url: API_BASE_URL + 'sharedvideo/' + options.eventId,
    method: 'post',
    ...options,
  })
}

export const updateSharedVideo = options => {
  return request({
    url: API_BASE_URL + 'sharedvideo/' + options.eventId,
    method: 'put',
    ...options,
  })
}

export const deleteSharedVideo = options => {
  return request({
    url: API_BASE_URL + 'sharedvideo/' + options.eventId + '/delete',
    method: 'post',
    ...options,
  })
}

export const setSharedVideosOrder = options => {
  return request({
    url: API_BASE_URL + 'sharedvideo/setOrder'
      + '/' + `${options.eventId}`,
    method: 'post',
    ...options
  })
}

export const getVideoInfo = options => {
  return request({
    url: API_BASE_URL + 'sharedvideo/' + options.eventId + '/videoinfo',
    method: 'post',
    ...options,
  })
}

export const getMyBundleSites = options => {
  return request({
    url: API_BASE_URL + 'bundlesite/me',
    method: 'get',
    ...options,
  })
}

export const getBundleSite = options => {
  return request({
    url: API_BASE_URL + 'bundlesite/' + `${options.bundleSIteId}`,
    method: 'get',
    ...options,
  })
}

export const saveBundleSite = options => {
  return request({
    url: API_BASE_URL + 'bundlesite/',
    method: 'post',
    ...options
  })
}

export const updateBundleSite = options => {
  return request({
    url: API_BASE_URL + 'bundlesite/' + `${options.data.id}`,
    method: 'put',
    ...options
  })
}

export const deleteBundleSite = options => {
  return request({
    url: API_BASE_URL + 'bundlesite/' + `${options.bundleSIteId}`,
    method: 'delete',
    ...options
  })
}

export const getEventPublishStatuses = options => {
  return request({
    url: API_BASE_URL + 'bundlesite/event/publish_statuses',
    method: 'post',
    data: options,
    ...options
  })
}

export const checkOptionVcube = options => {
  return request({
    url: API_BASE_URL + 'vcube/checkOptionVcube',
    method: 'get',
    ...options
  })
}

export const getReferrerUrlVcube = options => {
  return request({
    url: API_BASE_URL + 'vcube/getReferrerUrl/' + `${options.eventId}`,
    method: 'get',
    ...options
  })
}

export const getZoomAccounts = options => {
  return request({
    url: API_BASE_URL + 'zoom/accounts',
    method: 'get',
    ...options
  })
}

export const getAllowAssignZoomAccounts = options => {
  return request({
    url: API_BASE_URL + 'zoom/event/allowAssignAccounts'
      + '/' + `${options.eventGroupId}`,
    method: 'get',
    ...options
  })
}

export const getZoomAccount = options => {
  return request({
    url: API_BASE_URL + 'zoom/account/' + `${options.zoomAccountId}`,
    method: 'get',
    ...options
  })
}

export const checkSameEmailZoomAccountOfSameClient = options => {
  return request({
    url: API_BASE_URL + 'zoom/account/checkSameEmail/sameClient',
    method: 'post',
    data: options,
    ...options
  })
}

export const checkSameEmailZoomAccountOfOtherClients = options => {
  return request({
    url: API_BASE_URL + 'zoom/account/checkSameEmail/otherClients',
    method: 'post',
    data: options,
    ...options
  })
}

export const addZoomAccount = options => {
  return request({
    url: API_BASE_URL + 'zoom/account',
    method: 'post',
    data: options,
    ...options
  })
}

export const updateZoomAccount = options => {
  return request({
    url: API_BASE_URL + 'zoom/account',
    method: 'put',
    data: options,
    ...options
  })
}

export const deleteZoomAccounts = options => {
  return request({
    url: API_BASE_URL + 'zoom/account/delete',
    method: 'post',
    ...options
  })
}

export const getZoomOAuthUrl = options => {
  return request({
    url: API_BASE_URL + 'zoom/account/oauthUrl/' + `${options.zoomAccountId}`,
    method: 'get',
    ...options
  })
}

export const getZoomOAuthUrlAfterGettingCode = options => {
  return request({
    url: API_BASE_URL + 'zoom/account/oauth/urlAfterGettingCode',
    method: 'get',
    ...options
  })
}

export const getZoomAccessToken = options => {
  return request({
    url: API_BASE_URL + 'zoom/getAccessToken'
      + '/' + `${options.zoomAccountId}`
      + '/' + `${options.code}`,
    method: 'get',
    ...options
  })
}

export const deleteZoomAccessToken = options => {
  return request({
    url: API_BASE_URL + 'zoom/deleteAccessToken'
      + '/' + `${options.zoomAccountId}`,
    method: 'get',
    ...options
  })
}

export const getZoomEventSettings = options => {
  return request({
    url: API_BASE_URL + 'zoom/eventSettings'
      + '/' + `${options.eventId}`,
    method: 'get',
    ...options
  })
}

export const putZoomEventSettings = options => {
  return request({
    url: API_BASE_URL + 'zoom/eventSettings',
    method: 'put',
    ...options
  })
}

export const getZoomVisitor = options => {
  return request({
    url: API_BASE_URL + 'zoom/visitors'
      + '/' + `${options.eventId}`,
    method: 'get',
    ...options
  })
}

export const doReflectAllZoomVisitors = options => {
  return request({
    url: API_BASE_URL + 'zoom/reflectAll',
    method: 'put',
    ...options
  })
}

export const doReflectIndividualZoomVisitors = options => {
  return request({
    url: API_BASE_URL + 'zoom/reflectIndividual',
    method: 'put',
    ...options
  })
}

export const getAllSurveys = options => {
  return request({
    url: API_BASE_URL + 'survey/all'
      + '/' + `${options.eventId}`,
    method: 'get',
    ...options
  })
}
export const createSurvey = options => {
  return request({
    url: API_BASE_URL + 'survey',
    method: 'post',
    ...options
  })
}
export const updateSurvey = options => {
  return request({
    url: API_BASE_URL + 'survey',
    method: 'put',
    ...options
  })
}
export const setSurveysOrder = options => {
  return request({
    url: API_BASE_URL + 'survey/setOrder'
      + '/' + `${options.eventId}`,
    method: 'post',
    ...options
  })
}
export const deleteSurvey = options => {
  return request({
    url: API_BASE_URL + 'survey/' + options.surveyId,
    method: 'delete',
    ...options
  })
}
export const doLandingSurvey = options => {
  return request({
    url: API_BASE_URL + 'survey/doLandingSurvey/' + options.eventId,
    method: 'get',
    ...options
  })
}

export const getAllQuizzes = options => {
  return request({
    url: API_BASE_URL + 'quiz/all'
      + '/' + `${options.eventId}`,
    method: 'get',
    ...options
  })
}
export const createQuiz = options => {
  return request({
    url: API_BASE_URL + 'quiz',
    method: 'post',
    ...options
  })
}
export const updateQuiz = options => {
  return request({
    url: API_BASE_URL + 'quiz',
    method: 'put',
    ...options
  })
}
export const setQuizzesOrder = options => {
  return request({
    url: API_BASE_URL + 'quiz/setOrder'
      + '/' + `${options.eventId}`,
    method: 'post',
    ...options
  })
}
export const deleteQuiz = options => {
  return request({
    url: API_BASE_URL + 'quiz/' + options.quizId,
    method: 'delete',
    ...options
  })
}
export const doLandingQuiz = options => {
  return request({
    url: API_BASE_URL + 'quiz/doLandingQuiz/' + options.eventId,
    method: 'get',
    ...options
  })
}
