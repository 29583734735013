import '@fortawesome/fontawesome-free/css/all.css'
import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
    theme: {
        themes: {
            light: {
              primary: '#2DA9EF',
              secondary: "#ffc107",
              error: '#F8504A',
              info: '#2196F3',
              success: '#4CAF50',
              warning: '#FFC107',
              accent: '#3F51B5'
            },
        },
      options: { customProperties: true }
    },
});
