export default {
  methods: {
    getUri() {
      return new URL(window.location.href);
    },
    isServersEnvProduction() {
      let uri = this.getUri()
      return /^(event|member|admin)\.event-planner\.net$/.test(uri.hostname)
    },
    isServersEnvStaging() {
      let uri = this.getUri()
      return /^(event|member|admin)\.stg\.event-planner\.net$/.test(uri.hostname)
    },
    getMemberGtmIds() {
      let ids = {
        gtmId: 'GTM-5DHJJGHT',
        gtmAuth: 'DUMMY',
        gtmPreview: 'DUMMY'
      }

      if(this.isServersEnvProduction()) {
        ids.gtmAuth = '-o0qo2cEPYU3y5FRmEvKxQ'
        ids.gtmPreview = 'env-1'
        return ids
      } else if(this.isServersEnvStaging()) {
        ids.gtmAuth = '3Vb7JUGjaUIBEyfzqGYeUA'
        ids.gtmPreview = 'env-3'
        return ids
      } else {
        ids.gtmAuth = 'aT92WH9uwQNyIoK5hm06Yg'
        ids.gtmPreview = 'env-4'
        return ids
      }
    },
    getEventSiteGtmIds() {
      let ids = {
        gtmId: 'GTM-NZ8HC7V',
        gtmAuth: 'DUMMY',
        gtmPreview: 'DUMMY'
      }

      if(this.isServersEnvProduction()) {
        ids.gtmAuth = '4vXK5mA6X2ZAuMPDIaHc0Q'
        ids.gtmPreview = 'env-2'
        return ids
      } else if(this.isServersEnvStaging()) {
        ids.gtmAuth = 'WSCE_HQX1UvDDD5V0rauqQ'
        ids.gtmPreview = 'env-50'
        return ids
      } else {
        ids.gtmAuth = 'M_l_pgTLnMTBp8EC-1a5tw'
        ids.gtmPreview = 'env-49'
        return ids
      }
    },
    getGtmTool() {
      return {
        eventId: 0,
        eventPublishId: '',
        mountedDateTime: 0,
        mountedTiming: 0,
        taskMap: new Map(),
        getTiming: function(name) {
          let entries = window.performance.getEntriesByType("navigation")[0];
          if(name in entries) {
            return entries[name] - entries.fetchStart
          }
        },
        getTimingFromMounted: function() {
          return new Date().getTime() - this.mountedDateTime
        },
        setEventId: function(eventId) {
          this.eventId = eventId

          dataLayer.push({
            CPRM_eventId: this.eventId
          })
        },
        setEventPublishId: function(eventPublishId) {
          this.eventPublishId = eventPublishId

          dataLayer.push({
            CPRM_eventPublishId: this.eventPublishId
          })
        },
        setMounted: function() {
          this.mountedDateTime = new Date().getTime()

          let entries = window.performance.getEntriesByType("navigation")[0];
          this.mountedTiming = entries.domContentLoadedEventEnd
        },
        sendTimingFromMounted: function(name, recordCount) {
          dataLayer.push({
            CPRM_timing: this.getTimingFromMounted(),
            CPRM_recordName: name,
            CPRM_recordCount: (recordCount !== undefined || recordCount === null) ? recordCount : 1,
            event: 'CJS_pageSpeed'
          })
        },
        sendBasicTiming: function(name) {
          dataLayer.push({
            CPRM_timing: this.getTiming(name),
            CPRM_recordName: name,
            CPRM_recordCount: 1,
            event: 'CJS_pageSpeed'
          })
        },
        sendBasicTimings: function() {
          this.sendBasicTiming('responseEnd')
          this.sendBasicTiming('domInteractive')

          this.sendLoadEventEnd(this)
        },
        sendLoadEventEnd(self) {
          let loadEventEnd = self.getTiming('loadEventEnd')
          if(loadEventEnd > 0) {
            self.sendBasicTiming('loadEventEnd')
          } else {
            setTimeout(self.sendLoadEventEnd, 2000, self)
          }
        },
        sendLoadMainRecordsStart: function() {
          this.sendTimingFromMounted('MNTD_loadMainRecordStart')
        },
        sendLoadMainRecordsEnd: function(recordCount) {
          this.sendTimingFromMounted('MNTD_loadMainRecordEnd', recordCount)
        },
        sendSpecificTiming: function(name, recordCount) {
          this.sendTimingFromMounted('MNTD_' + name, recordCount)
        },
      }
    },
  }
}
